import clsx from 'clsx'
import { Link } from 'routes/components/Shared'
import { Carousel, useCarousel } from 'routes/components/Shared/Carousel'
import { LINKS } from 'routes/constants'
import { companiesData } from './companiesData'
import classes from './LandingCompanies.module.scss'
import { LandingCompaniesCard } from './LandingCompaniesCard'

export const LandingCompanies = () => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <h2 className={clsx('page__title', classes.title)}>
        B2B SaaS companies trust our platform.
      </h2>
      <p className={clsx('page__subtitle', classes.subtitle)}>
        From startups to enterprises, we power everything
        from one-click integrations to AI-driven automation solutions.
        We deliver seamless connectivity for all B2B SaaS needs.     </p>

      <Slider />

      <div className={clsx('page__actions', classes.actions)}>
        {/* <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href={LINKS.SOLUTIONS}
        >
          Explore Solutions
        </Link> */}
        <Link
          className={'page__button'}
          type='secondary'
          href={LINKS.CASE_STUDIES}
        >
          Read Case Studies
        </Link>
      </div>
    </section>
  )
}

function Slider() {
  const { slidesToRender, carouselSettings } = useCarousel(companiesData)

  return (
    <Carousel className={classes.slider} {...carouselSettings}>
      {slidesToRender.map((card, index) => {
        return (
          <div className={classes.slide} key={index}>
            <LandingCompaniesCard card={card} />
          </div>
        )
      })}
    </Carousel>
  )
}
