import clsx from 'clsx'
import { BookCalendlyButton, Link } from 'routes/components/Shared'
import { DOCS_LINK, LINKS } from 'routes/constants'
import HeroDeskImage1x from './landing-hero-desk@1x.png'
import HeroDeskImage2x from './landing-hero-desk@2x.png'
import HeroMobImage1x from './landing-hero-mob@1x.png'
import HeroMobImage2x from './landing-hero-mob@2x.png'
import classes from './LandingHero.module.scss'

export const LandingHero = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <h1 className={classes.title}>
          Integration infrastructure for B2B SaaS development stacks
        </h1>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          Empower your app with seamless connectivity. Deliver a native user experience. Future-proof your integration strategy.

        </p>
        <Link
          className={classes.link}
          type='noLineGradient'
          href={LINKS.HOW_WE_ARE_DIFFERENT}
        >
          How is it different from iPaaS and unified APIs?
        </Link>
      </div>

      <Actions />

      <div className={classes.visual}>
        <picture className={classes.image}>
          <source
            media='(min-resolution: 2dppx) and (min-width: 768px)'
            srcSet={HeroDeskImage2x.src}
          />
          <source media='(min-width: 768px)' srcSet={HeroDeskImage1x.src} />
          <source
            media='(min-resolution: 2dppx) and (max-width: 767px)'
            srcSet={HeroMobImage2x.src}
          />
          <source media='(max-width: 767px)' srcSet={HeroMobImage1x.src} />
          <img
            src={HeroMobImage1x.src}
            alt='The integration layer of your SaaS development stack'
            loading='eager'
          />
        </picture>
      </div>
    </section>
  )
}

const Actions = () => {
  return (
    <div className={clsx('page__actions', classes.actions)}>
      <Link
        className={clsx('page__button', classes.button, classes.button__try)}
        type='primary'
        href='https://console.integration.app/login'
      >
        Try it Free
      </Link>

      <Link
        className={clsx('page__button', classes.button, classes.button__read)}
        type='secondary'
        href={DOCS_LINK}
      >
        API documentation
      </Link>

      <BookCalendlyButton
        className={clsx('page__button', classes.button, classes.button__book)}
        category='secondary'
        isReversed
      >
        <span>Schedule demo</span>
      </BookCalendlyButton>
    </div>
  )
}
